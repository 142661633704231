import React from "react";
import { Container } from "basis";
import { useQueryParam, StringParam } from "use-query-params";

import { logger } from "../core";
import { ErrorMessage } from "../components";
import { isBrowser } from "../utils";

const ErrorPage = () => {
  const [message] = useQueryParam("message", StringParam);
  const [description] = useQueryParam("description", StringParam);
  const [transactionId] = useQueryParam("transactionId", StringParam);
  const [urlCancel] = useQueryParam("urlCancel", StringParam);

  const additionalParams = {
    transactionId,
    urlCancel,
  };

  if (isBrowser()) {
    logger.error(message);
  }

  return (
    <Container padding="6 8" hasBreakpointWidth>
      <ErrorMessage
        type={message}
        description={description}
        additionalParams={additionalParams}
      />
    </Container>
  );
};

export default ErrorPage;
